<script>
export default {
  name: 'LogoSanctuary',
  props: {
    fill: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
    },
  },
}
</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0.00 0.00 1500.00 802.00">
    <g stroke-width="2.00" fill="none" stroke-linecap="butt">
      <path
        :stroke="fill ? '#645726' : 'currentColor'" vector-effect="non-scaling-stroke" d="
  M 950.23 330.60
  Q 946.01 331.07 942.28 334.04"
      />
      <path
        :stroke="fill ? '#645726' : 'currentColor'" vector-effect="non-scaling-stroke" d="
  M 1029.80 378.01
  L 1019.06 383.23"
      />
      <path
        :stroke="fill ? '#645726' : 'currentColor'" vector-effect="non-scaling-stroke" d="
  M 891.79 405.93
  Q 892.40 406.96 894.01 406.52"
      />
      <path
        :stroke="fill ? '#645726' : 'currentColor'" vector-effect="non-scaling-stroke" d="
  M 277.89 550.52
  Q 269.75 550.91 261.37 550.91
  Q 259.19 550.91 257.00 550.43"
      />
      <path
        :stroke="fill ? '#645726' : 'currentColor'" vector-effect="non-scaling-stroke" d="
  M 280.46 589.36
  Q 281.13 588.93 279.56 588.53
  Q 279.20 588.44 279.20 588.82
  L 279.36 634.73"
      />
      <path
        :stroke="fill ? '#645726' : 'currentColor'" vector-effect="non-scaling-stroke" d="
  M 348.00 550.46
  Q 337.15 551.46 325.99 550.39"
      />
      <path
        :stroke="fill ? '#645726' : 'currentColor'" vector-effect="non-scaling-stroke" d="
  M 422.73 550.36
  Q 413.22 551.41 403.76 550.38"
      />
      <path
        :stroke="fill ? '#645726' : 'currentColor'" vector-effect="non-scaling-stroke" d="
  M 563.74 550.40
  Q 551.11 551.37 538.50 550.43"
      />
      <path
        :stroke="fill ? '#645726' : 'currentColor'" vector-effect="non-scaling-stroke" d="
  M 553.96 652.92
  L 533.94 652.66
  A 1.07 1.06 0.6 0 1 532.89 651.60
  Q 532.85 626.63 532.87 597.25
  Q 532.87 583.88 532.31 570.54"
      />
      <path
        :stroke="fill ? '#645726' : 'currentColor'" vector-effect="non-scaling-stroke" d="
  M 755.49 550.41
  L 724.53 550.75
  Q 724.04 550.76 723.60 550.96
  L 723.02 551.22"
      />
      <path
        :stroke="fill ? '#645726' : 'currentColor'" vector-effect="non-scaling-stroke" d="
  M 869.21 550.34
  Q 856.07 551.19 843.01 550.49"
      />
      <path
        :stroke="fill ? '#645726' : 'currentColor'" vector-effect="non-scaling-stroke" d="
  M 885.21 570.66
  L 866.65 570.13
  A 1.36 1.36 0.0 0 0 865.25 571.49
  L 865.31 590.49
  A 1.21 1.20 89.5 0 0 866.52 591.69
  L 913.96 591.47"
      />
      <path
        :stroke="fill ? '#645726' : 'currentColor'" vector-effect="non-scaling-stroke" d="
  M 866.08 611.56
  Q 866.52 611.38 865.89 611.35
  A 0.52 0.51 -88.4 0 0 865.36 611.86
  Q 865.30 622.04 865.33 632.02
  Q 865.33 632.25 865.50 632.42
  Q 865.69 632.60 866.00 632.71"
      />
      <path
        :stroke="fill ? '#645726' : 'currentColor'" vector-effect="non-scaling-stroke" d="
  M 988.25 550.40
  Q 976.56 551.37 965.00 550.36"
      />
      <path
        :stroke="fill ? '#645726' : 'currentColor'" vector-effect="non-scaling-stroke" d="
  M 988.03 620.05
  Q 987.88 619.99 987.71 620.06
  Q 987.29 620.24 987.29 620.70
  L 987.23 651.51
  A 0.99 0.97 -28.2 0 1 987.06 652.06
  L 986.44 652.97"
      />
      <path
        :stroke="fill ? '#645726' : 'currentColor'" vector-effect="non-scaling-stroke" d="
  M 1183.98 550.40
  Q 1171.36 551.45 1159.00 550.38"
      />
      <path
        :stroke="fill ? '#645726' : 'currentColor'" vector-effect="non-scaling-stroke" d="
  M 886.89 0.00
  L 876.78 1.27"
      />
      <path
        :stroke="fill ? '#645726' : 'currentColor'" vector-effect="non-scaling-stroke" d="
  M 900.41 0.00
  L 910.81 1.54"
      />
      <path
        :stroke="fill ? '#645726' : 'currentColor'" vector-effect="non-scaling-stroke" d="
  M 733.46 320.07
  Q 728.64 323.40 731.06 328.74
  Q 731.95 330.71 736.77 334.30"
      />
      <path
        :stroke="fill ? '#645726' : 'currentColor'" vector-effect="non-scaling-stroke" d="
  M 729.31 293.34
  Q 729.77 293.39 729.91 292.60
  A 0.26 0.26 0.0 0 0 729.64 292.30
  L 719.77 292.61"
      />
      <path
        :stroke="fill ? '#645726' : 'currentColor'" vector-effect="non-scaling-stroke" d="
  M 664.19 322.81
  Q 662.18 326.45 664.67 329.52
  Q 666.78 332.11 676.52 340.45"
      />
      <path
        :stroke="fill ? '#645726' : 'currentColor'" vector-effect="non-scaling-stroke" d="
  M 668.19 287.63
  Q 669.13 287.43 669.67 286.71
  Q 669.99 286.29 669.59 285.93
  Q 668.75 285.19 667.46 285.63"
      />
      <path
        :stroke="fill ? '#645726' : 'currentColor'" vector-effect="non-scaling-stroke" d="
  M 832.01 78.56
  Q 831.03 81.44 831.42 84.45"
      />
      <path
        :stroke="fill ? '#645726' : 'currentColor'" vector-effect="non-scaling-stroke" d="
  M 0.78 639.00
  L 0.62 638.02
  Q 0.53 637.54 0.85 637.15
  Q 1.37 636.53 1.19 636.28"
      />
      <path
        :stroke="fill ? '#645726' : 'currentColor'" vector-effect="non-scaling-stroke" d="
  M 1424.93 550.54
  Q 1413.79 551.31 1402.22 550.39"
      />
      <path
        :stroke="fill ? '#645726' : 'currentColor'" vector-effect="non-scaling-stroke" d="
  M 1485.47 550.39
  L 1474.35 550.43"
      />
      <path
        :stroke="fill ? '#645726' : 'currentColor'" vector-effect="non-scaling-stroke" d="
  M 1213.30 611.03
  Q 1197.07 609.54 1180.91 610.75"
      />
      <path
        :stroke="fill ? '#645726' : 'currentColor'" vector-effect="non-scaling-stroke" d="
  M 1460.73 611.79
  L 1460.37 651.10
  Q 1460.37 651.37 1460.50 651.61
  Q 1460.67 651.89 1460.50 652.27
  A 0.55 0.54 11.3 0 1 1460.02 652.58
  L 1439.30 652.90"
      />
      <path
        :stroke="fill ? '#c8911a' : 'currentColor'" vector-effect="non-scaling-stroke" d="
  M 669.51 801.75
  L 669.46 801.26"
      />
    </g>
    <path
      :fill="fill ? '#c8911a' : 'currentColor'" d="
  M 865.62 0.00
  L 886.89 0.00
  L 876.78 1.27
  Q 857.59 0.44 839.12 0.69
  Q 839.11 0.69 839.11 0.66
  Q 839.11 0.63 839.11 0.65
  Q 852.37 0.38 865.62 0.00
  Z"
    />
    <path
      :fill="fill ? '#001c32' : 'currentColor'" d="
  M 886.89 0.00
  L 900.41 0.00
  L 910.81 1.54
  Q 940.21 7.58 957.67 28.08
  Q 973.65 46.85 978.64 71.27
  Q 980.08 78.32 981.11 86.19
  Q 981.18 86.74 981.71 86.87
  Q 984.76 87.64 987.95 88.17
  Q 993.88 89.16 999.50 90.00
  Q 1017.20 92.63 1029.50 95.01
  Q 1042.03 97.43 1053.75 102.04
  Q 1055.04 102.55 1056.12 103.34
  Q 1056.78 103.82 1055.98 103.97
  C 1053.99 104.36 1052.04 105.02 1050.00 105.02
  Q 1045.67 105.02 1041.31 105.62
  C 1038.17 106.06 1035.13 106.01 1031.99 106.38
  Q 1028.24 106.82 1024.49 107.24
  Q 1013.70 108.43 1002.13 110.23
  Q 989.13 112.25 976.93 115.54
  C 965.82 118.53 956.05 123.35 948.94 132.20
  Q 946.20 135.61 942.42 143.38
  Q 934.30 160.08 925.55 177.79
  Q 913.74 201.70 898.47 220.90
  C 869.63 257.18 828.69 278.39 782.98 287.22
  Q 772.24 289.30 765.46 290.30
  Q 764.96 290.38 764.59 290.73
  L 733.46 320.07
  Q 728.64 323.40 731.06 328.74
  Q 731.95 330.71 736.77 334.30
  L 845.21 436.71
  Q 845.73 437.19 845.02 437.21
  Q 835.43 437.44 825.00 437.70
  Q 819.30 437.85 813.70 436.88
  A 1.97 1.93 -18.2 0 1 812.71 436.39
  Q 781.43 407.12 749.47 377.07
  Q 728.70 357.53 711.69 340.76
  Q 706.89 336.03 703.26 330.90
  Q 699.25 325.24 703.74 319.02
  Q 706.95 314.57 711.94 309.93
  Q 720.44 302.05 729.31 293.34
  Q 729.77 293.39 729.91 292.60
  A 0.26 0.26 0.0 0 0 729.64 292.30
  L 719.77 292.61
  L 698.05 291.05
  Q 697.52 291.01 697.14 291.37
  L 664.19 322.81
  Q 662.18 326.45 664.67 329.52
  Q 666.78 332.11 676.52 340.45
  L 778.34 436.38
  Q 779.15 437.14 778.04 437.23
  Q 763.05 438.44 748.11 437.90
  A 1.41 1.38 -21.8 0 1 747.20 437.52
  Q 689.66 383.61 645.43 341.72
  Q 640.37 336.93 636.57 332.01
  Q 634.68 329.57 634.68 325.36
  Q 634.68 322.12 636.56 319.47
  Q 638.62 316.56 640.98 314.24
  C 650.02 305.34 659.33 296.72 668.19 287.63
  Q 669.13 287.43 669.67 286.71
  Q 669.99 286.29 669.59 285.93
  Q 668.75 285.19 667.46 285.63
  Q 662.84 283.89 658.01 282.42
  Q 636.60 275.91 617.92 263.81
  C 610.23 258.83 603.24 252.05 597.05 245.44
  Q 596.32 244.66 595.74 243.76
  Q 594.95 242.52 596.32 243.05
  Q 618.79 251.61 638.02 256.41
  Q 690.32 269.47 743.05 266.55
  Q 749.80 266.18 756.00 265.74
  Q 776.24 264.29 798.98 257.63
  Q 815.13 252.90 829.16 245.81
  C 856.95 231.77 879.06 209.54 894.53 182.82
  Q 899.31 174.57 903.78 165.51
  Q 913.53 145.74 922.96 127.00
  Q 931.09 110.84 937.15 102.53
  Q 943.14 94.31 952.94 88.26
  C 955.29 86.82 956.06 84.49 955.47 81.82
  Q 952.67 69.01 949.55 61.81
  Q 935.35 29.15 899.05 25.24
  Q 895.42 24.85 890.82 25.04
  Q 886.55 25.21 882.96 25.92
  Q 848.57 32.74 835.68 64.86
  C 833.92 69.24 832.97 73.92 832.01 78.56
  Q 831.03 81.44 831.42 84.45
  C 830.95 89.31 831.10 94.27 830.64 98.99
  C 830.17 103.63 830.17 108.29 829.63 112.93
  Q 829.29 115.84 828.92 118.72
  Q 825.25 147.43 810.58 171.71
  C 795.00 197.47 770.95 215.32 741.56 223.64
  Q 728.45 227.36 715.24 229.00
  Q 687.63 232.44 660.00 229.06
  Q 641.13 226.74 623.02 220.94
  Q 597.62 212.80 574.43 200.22
  Q 524.41 173.08 487.37 134.22
  C 474.82 121.06 462.99 106.47 451.84 92.33
  Q 447.79 87.19 443.88 81.49
  Q 443.39 80.79 444.25 80.78
  Q 445.24 80.77 446.22 81.03
  Q 468.27 86.88 487.77 90.73
  Q 527.31 98.55 570.00 102.33
  C 575.02 102.78 579.98 102.99 584.96 103.53
  C 589.50 104.03 594.12 103.88 598.69 104.33
  Q 605.95 105.04 613.17 105.00
  C 617.97 104.97 622.84 105.61 627.59 105.72
  C 648.11 106.19 676.26 106.47 699.74 105.27
  C 707.07 104.90 714.44 105.10 721.70 104.43
  C 727.40 103.91 733.06 104.13 738.61 103.48
  C 743.29 102.92 748.03 103.07 752.66 102.41
  C 756.15 101.91 759.63 102.02 763.10 101.54
  C 766.97 101.01 770.94 100.71 774.83 100.26
  Q 794.98 97.90 805.41 95.94
  Q 805.89 95.85 805.91 95.37
  Q 806.54 80.54 807.28 76.25
  C 807.97 72.26 808.65 68.05 809.77 64.31
  Q 821.41 25.27 858.23 7.25
  Q 862.71 5.06 876.78 1.27
  L 886.89 0.00
  Z"
    />
    <path
      :fill="fill ? '#c8911a' : 'currentColor'" d="
  M 900.41 0.00
  L 915.25 0.00
  Q 920.67 0.43 926.11 0.64
  Q 926.13 0.64 926.13 0.66
  Q 926.13 0.67 926.13 0.67
  Q 926.17 0.70 926.12 0.70
  Q 918.78 0.23 910.81 1.54
  L 900.41 0.00
  Z"
    />
    <ellipse :fill="fill ? '#001c32' : 'currentColor'" cx="0.00" cy="0.00" transform="translate(908.73,66.47) rotate(-5.7)" rx="18.74" ry="18.28" />
    <path
      :fill="fill ? '#c8911a' : 'currentColor'" d="
  M 832.01 78.56
  L 831.42 84.45
  Q 831.03 81.44 832.01 78.56
  Z"
    />
    <path
      :fill="fill ? '#c8911a' : 'currentColor'" d="
  M 668.19 287.63
  L 668.56 287.04
  Q 668.87 286.54 668.37 286.21
  L 667.46 285.63
  Q 668.75 285.19 669.59 285.93
  Q 669.99 286.29 669.67 286.71
  Q 669.13 287.43 668.19 287.63
  Z"
    />
    <path
      :fill="fill ? '#c8911a' : 'currentColor'" d="
  M 729.31 293.34
  L 719.77 292.61
  L 729.64 292.30
  A 0.26 0.26 0.0 0 1 729.91 292.60
  Q 729.77 293.39 729.31 293.34
  Z"
    />
    <path
      :fill="fill ? '#001c32' : 'currentColor'" d="
  M 950.23 330.60
  Q 946.01 331.07 942.28 334.04
  Q 937.12 336.51 931.94 339.12
  Q 929.60 340.29 927.57 340.27
  Q 919.01 340.16 910.37 340.27
  Q 908.31 340.30 906.38 339.28
  Q 899.13 335.47 892.08 332.06
  Q 884.27 328.28 876.02 329.58
  Q 870.87 330.39 864.05 333.98
  Q 858.98 336.65 853.06 339.51
  Q 851.41 340.31 849.44 340.29
  Q 840.38 340.17 830.83 340.24
  A 3.38 3.36 -30.4 0 1 829.20 339.82
  Q 822.71 336.25 815.99 333.08
  Q 811.23 330.83 806.13 329.84
  C 799.37 328.53 793.04 330.06 786.98 333.08
  Q 781.57 335.79 776.31 338.84
  Q 775.81 339.13 775.41 338.72
  L 762.77 325.78
  Q 762.00 324.99 762.89 324.33
  Q 768.22 320.38 774.06 316.39
  Q 779.48 312.69 785.33 310.88
  Q 796.89 307.29 808.89 308.82
  C 817.40 309.91 825.34 313.99 833.12 317.99
  Q 840.79 321.94 848.13 318.27
  Q 853.47 315.59 858.84 313.08
  Q 866.82 309.35 875.93 308.26
  C 883.94 307.30 893.51 309.52 900.63 312.84
  Q 906.42 315.55 911.79 318.23
  Q 918.82 321.74 926.04 318.30
  Q 931.64 315.64 937.64 312.83
  Q 952.49 305.88 967.83 309.13
  Q 975.81 310.82 985.83 316.24
  C 991.98 319.57 997.96 321.52 1004.84 318.28
  C 1011.97 314.92 1018.89 311.21 1026.58 309.05
  Q 1027.55 308.78 1028.60 308.84
  Q 1029.34 308.88 1029.41 309.62
  Q 1030.34 319.38 1029.92 329.48
  Q 1029.90 329.97 1029.42 330.10
  C 1022.19 332.09 1016.43 337.11 1009.29 339.31
  Q 1007.11 339.99 1005.07 340.06
  Q 997.43 340.31 988.89 340.24
  Q 987.06 340.22 985.16 339.23
  Q 979.95 336.52 975.00 334.14
  Q 967.28 330.44 960.75 329.27
  Q 955.68 328.37 950.23 330.60
  Z"
    />
    <path
      :fill="fill ? '#c8911a' : 'currentColor'" d="
  M 587.64 320.18
  L 608.44 339.57
  Q 608.89 339.99 608.27 340.01
  C 561.93 341.37 517.59 341.07 471.46 340.96
  A 0.46 0.46 0.0 0 1 471.00 340.50
  L 471.01 320.75
  A 0.75 0.74 90.0 0 1 471.75 320.00
  L 587.19 320.00
  A 0.66 0.65 66.5 0 1 587.64 320.18
  Z"
    />
    <path
      :fill="fill ? '#c8911a' : 'currentColor'" d="
  M 733.46 320.07
  C 732.23 321.98 730.47 323.66 730.95 326.26
  C 731.42 328.84 735.01 332.32 736.77 334.30
  Q 731.95 330.71 731.06 328.74
  Q 728.64 323.40 733.46 320.07
  Z"
    />
    <path
      :fill="fill ? '#c8911a' : 'currentColor'" d="
  M 664.19 322.81
  Q 663.25 326.47 666.00 329.50
  Q 671.09 335.12 676.52 340.45
  Q 666.78 332.11 664.67 329.52
  Q 662.18 326.45 664.19 322.81
  Z"
    />
    <path
      :fill="fill ? '#c8911a' : 'currentColor'" d="
  M 950.23 330.60
  L 942.28 334.04
  Q 946.01 331.07 950.23 330.60
  Z"
    />
    <path
      :fill="fill ? '#001c32' : 'currentColor'" d="
  M 1029.80 378.01
  L 1019.06 383.23
  Q 1010.18 388.08 1005.70 388.93
  Q 1002.72 389.49 990.93 389.07
  C 989.08 389.00 986.92 388.31 985.21 387.53
  Q 978.67 384.53 971.99 381.09
  C 968.33 379.20 964.45 378.25 960.29 378.20
  C 955.27 378.14 950.68 378.28 946.13 380.53
  Q 939.31 383.91 932.13 387.36
  C 930.88 387.96 928.52 388.86 927.16 388.95
  Q 918.74 389.48 911.52 388.90
  Q 910.02 388.78 905.81 386.84
  Q 901.06 384.66 896.27 382.42
  Q 890.01 379.50 886.57 378.78
  Q 880.41 377.50 873.72 378.73
  C 867.30 379.92 860.53 384.09 854.37 386.86
  C 851.97 387.94 849.28 388.94 846.65 389.04
  Q 840.52 389.28 834.70 388.94
  Q 829.80 388.65 826.52 385.86
  Q 810.88 372.58 797.16 357.75
  A 0.71 0.71 0.0 0 1 797.63 356.56
  Q 810.53 355.72 822.41 361.15
  C 827.24 363.35 832.40 367.03 837.48 367.86
  Q 843.56 368.85 849.51 365.97
  Q 858.13 361.79 863.90 359.52
  Q 879.93 353.20 895.96 359.48
  Q 902.19 361.92 909.93 365.75
  Q 915.75 368.63 921.73 367.93
  Q 925.46 367.49 930.95 364.62
  Q 935.26 362.35 939.75 360.47
  Q 959.59 352.16 978.99 360.99
  Q 983.04 362.84 986.70 364.88
  Q 991.53 367.57 994.66 367.95
  Q 1000.58 368.66 1006.26 366.05
  C 1013.83 362.59 1021.35 358.17 1029.31 356.97
  Q 1029.94 356.87 1029.94 357.51
  L 1029.80 378.01
  Z"
    />
    <path
      :fill="fill ? '#c8911a' : 'currentColor'" d="
  M 640.52 370.15
  C 647.24 375.96 653.59 382.32 660.16 388.60
  A 0.23 0.23 0.0 0 1 660.01 389.00
  L 471.25 389.00
  A 0.25 0.25 0.0 0 1 471.00 388.75
  L 471.01 368.70
  A 0.70 0.70 0.0 0 1 471.71 368.00
  Q 529.90 367.98 586.68 368.01
  Q 615.11 368.02 637.11 368.89
  C 638.26 368.94 639.67 369.41 640.52 370.15
  Z"
    />
    <path
      :fill="fill ? '#c8911a' : 'currentColor'" d="
  M 1029.80 378.01
  L 1019.06 383.23
  L 1029.80 378.01
  Z"
    />
    <path
      :fill="fill ? '#001c32' : 'currentColor'" d="
  M 891.79 405.93
  Q 892.40 406.96 894.01 406.52
  Q 901.46 410.13 909.00 413.74
  C 915.14 416.67 920.63 417.61 927.32 414.51
  Q 933.99 411.43 941.26 407.93
  C 943.10 407.05 945.68 406.15 947.71 405.96
  Q 954.83 405.31 960.50 405.32
  Q 970.51 405.33 978.85 409.42
  Q 984.01 411.95 989.09 414.62
  C 995.61 418.06 1001.83 416.54 1008.04 413.60
  Q 1017.11 409.31 1024.19 406.54
  Q 1026.61 405.60 1029.44 405.08
  Q 1030.00 404.97 1030.00 405.54
  L 1029.99 425.47
  A 1.33 1.33 0.0 0 1 1029.23 426.67
  Q 1019.19 431.50 1009.48 436.36
  Q 1007.57 437.31 1005.57 437.28
  Q 997.54 437.15 989.39 437.27
  Q 987.37 437.30 985.26 436.20
  Q 978.51 432.68 971.90 429.44
  Q 958.34 422.78 945.24 429.38
  Q 938.38 432.84 931.59 436.26
  Q 929.51 437.31 927.48 437.28
  Q 919.59 437.16 911.32 437.27
  Q 909.23 437.29 907.05 436.18
  C 899.20 432.18 890.40 427.43 882.00 426.15
  Q 876.70 425.35 870.25 427.62
  Q 869.63 427.84 869.16 427.38
  L 854.51 412.75
  Q 854.09 412.33 854.61 412.04
  Q 864.12 406.75 868.26 406.04
  Q 876.08 404.71 891.79 405.93
  Z"
    />
    <path
      :fill="fill ? '#c8911a' : 'currentColor'" d="
  M 891.79 405.93
  Q 892.95 405.78 894.01 406.52
  Q 892.40 406.96 891.79 405.93
  Z"
    />
    <path
      :fill="fill ? '#c8911a' : 'currentColor'" d="
  M 711.00 437.29
  Q 685.45 437.61 659.24 437.56
  Q 561.84 437.36 472.34 437.20
  Q 471.86 437.20 471.84 436.73
  L 471.00 417.45
  A 0.65 0.64 -1.3 0 1 471.65 416.78
  Q 555.22 417.00 643.06 417.01
  C 658.98 417.01 674.38 417.47 690.08 418.02
  A 4.09 4.08 -22.9 0 1 692.63 419.05
  Q 702.29 427.73 711.18 436.86
  Q 711.59 437.28 711.00 437.29
  Z"
    />
    <path
      :fill="fill ? '#001c32' : 'currentColor'" d="
  M 0.78 639.00
  L 0.62 638.02
  Q 0.53 637.54 0.85 637.15
  Q 1.37 636.53 1.19 636.28
  L 12.85 622.73
  A 0.68 0.68 0.0 0 1 13.78 622.63
  C 19.48 626.87 25.68 630.74 32.65 632.54
  C 40.37 634.54 57.31 636.62 58.92 624.66
  C 59.77 618.29 48.20 614.52 43.76 613.13
  Q 38.47 611.48 33.51 610.09
  Q 23.50 607.28 16.00 602.95
  Q 6.77 597.62 5.05 587.37
  C 4.22 582.38 3.99 577.34 5.06 572.32
  C 7.59 560.53 20.05 552.12 30.95 550.26
  Q 33.73 549.78 36.64 549.37
  Q 39.58 548.95 42.43 549.14
  Q 52.71 549.83 58.76 551.98
  Q 66.73 554.82 74.81 559.44
  Q 78.32 561.45 76.18 564.70
  Q 71.88 571.23 67.20 577.79
  Q 66.24 579.13 64.88 578.18
  Q 54.74 571.08 43.96 569.39
  C 38.46 568.52 28.78 569.37 27.22 575.91
  Q 25.60 582.74 31.51 585.73
  C 33.38 586.67 35.25 587.62 37.24 588.23
  Q 46.86 591.16 56.50 594.04
  Q 58.74 594.71 64.45 597.30
  C 71.48 600.48 77.90 605.85 79.72 613.53
  Q 81.56 621.30 79.96 629.82
  Q 77.39 643.51 64.68 649.57
  Q 59.14 652.22 54.00 652.98
  Q 50.62 653.49 46.99 653.78
  C 42.20 654.17 37.38 653.64 32.68 652.97
  Q 22.33 651.49 14.83 647.55
  Q 7.86 643.88 1.68 639.31
  Q 1.24 638.99 0.78 639.00
  Z"
    />
    <path
      :fill="fill ? '#001c32' : 'currentColor'" d="
  M 143.24 630.84
  L 134.69 651.98
  A 1.59 1.58 -79.1 0 1 133.24 652.97
  L 111.60 653.00
  Q 111.20 653.00 111.36 652.64
  L 154.45 551.67
  A 1.65 1.65 0.0 0 1 155.80 550.67
  Q 165.63 549.65 175.82 550.17
  A 0.92 0.91 -9.6 0 1 176.62 550.73
  L 219.61 651.89
  A 0.51 0.51 0.0 0 1 219.17 652.60
  Q 208.16 653.31 196.92 652.88
  Q 196.47 652.86 196.30 652.45
  L 187.49 630.74
  A 0.82 0.82 0.0 0 0 186.73 630.23
  L 144.15 630.23
  Q 143.49 630.23 143.24 630.84
  Z
  M 164.21 579.24
  L 154.31 603.03
  Q 154.09 603.56 154.19 604.12
  Q 154.38 605.21 153.60 605.94
  Q 152.27 607.18 151.93 609.00
  A 0.83 0.83 0.0 0 0 152.75 609.99
  L 177.38 609.99
  A 0.89 0.89 0.0 0 0 178.23 608.83
  Q 177.97 608.00 178.01 607.12
  A 1.86 1.84 -66.9 0 0 177.46 605.73
  C 175.63 603.94 176.40 602.04 175.17 600.14
  Q 174.08 598.45 173.25 596.67
  C 172.94 596.01 173.11 595.38 172.88 594.74
  Q 169.74 586.03 166.37 579.18
  Q 165.21 576.82 164.21 579.24
  Z"
    />
    <path
      :fill="fill ? '#c8911a' : 'currentColor'" d="
  M 277.89 550.52
  Q 269.75 550.91 261.37 550.91
  Q 259.19 550.91 257.00 550.43
  Q 267.58 549.29 277.89 550.52
  Z"
    />
    <path
      :fill="fill ? '#c8911a' : 'currentColor'" d="
  M 348.00 550.46
  Q 337.15 551.46 325.99 550.39
  Q 337.15 549.37 348.00 550.46
  Z"
    />
    <path
      :fill="fill ? '#c8911a' : 'currentColor'" d="
  M 422.73 550.36
  Q 413.22 551.41 403.76 550.38
  L 422.73 550.36
  Z"
    />
    <path
      :fill="fill ? '#c8911a' : 'currentColor'" d="
  M 563.74 550.40
  Q 551.11 551.37 538.50 550.43
  Q 551.08 549.67 563.74 550.40
  Z"
    />
    <path
      :fill="fill ? '#c8911a' : 'currentColor'" d="
  M 755.49 550.41
  L 724.53 550.75
  Q 724.04 550.76 723.60 550.96
  L 723.02 551.22
  Q 722.98 550.88 723.05 550.62
  Q 723.18 550.10 723.72 550.10
  L 755.49 550.41
  Z"
    />
    <path
      :fill="fill ? '#c8911a' : 'currentColor'" d="
  M 869.21 550.34
  Q 856.07 551.19 843.01 550.49
  L 869.21 550.34
  Z"
    />
    <path
      :fill="fill ? '#c8911a' : 'currentColor'" d="
  M 988.25 550.40
  Q 976.56 551.37 965.00 550.36
  Q 976.48 549.56 988.25 550.40
  Z"
    />
    <path
      :fill="fill ? '#c8911a' : 'currentColor'" d="
  M 1183.98 550.40
  Q 1171.36 551.45 1159.00 550.38
  Q 1171.36 549.57 1183.98 550.40
  Z"
    />
    <path
      :fill="fill ? '#001c32' : 'currentColor'" d="
  M 1308.13 630.36
  L 1299.41 651.87
  A 0.58 0.58 0.0 0 1 1298.89 652.23
  L 1276.97 652.83
  A 0.58 0.58 0.0 0 1 1276.42 652.02
  L 1319.56 550.43
  A 0.58 0.58 0.0 0 1 1320.12 550.07
  L 1340.90 550.85
  A 0.58 0.58 0.0 0 1 1341.41 551.20
  L 1384.58 652.23
  A 0.58 0.58 0.0 0 1 1384.02 653.03
  L 1361.27 652.15
  A 0.58 0.58 0.0 0 1 1360.76 651.79
  L 1352.02 630.36
  A 0.58 0.58 0.0 0 0 1351.48 630.00
  L 1308.67 630.00
  A 0.58 0.58 0.0 0 0 1308.13 630.36
  Z
  M 1342.73 609.30
  A 0.67 0.67 0.0 0 0 1343.00 608.47
  L 1330.74 578.28
  A 0.67 0.67 0.0 0 0 1329.50 578.28
  L 1316.77 609.02
  A 0.67 0.67 0.0 0 0 1317.39 609.94
  L 1341.34 610.02
  A 0.67 0.67 0.0 0 0 1341.69 609.92
  L 1342.73 609.30
  Z"
    />
    <path
      :fill="fill ? '#c8911a' : 'currentColor'" d="
  M 1424.93 550.54
  Q 1413.79 551.31 1402.22 550.39
  Q 1413.99 549.32 1424.93 550.54
  Z"
    />
    <path
      :fill="fill ? '#c8911a' : 'currentColor'" d="
  M 1485.47 550.39
  L 1474.35 550.43
  Q 1479.34 549.58 1485.47 550.39
  Z"
    />
    <path
      :fill="fill ? '#001c32' : 'currentColor'" d="
  M 403.76 550.38
  Q 413.22 551.41 422.73 550.36
  C 429.77 550.72 437.08 550.57 444.08 551.38
  Q 447.23 551.74 450.18 552.15
  Q 454.28 552.73 459.77 555.21
  Q 483.35 565.92 488.76 589.87
  Q 490.49 597.54 489.93 605.01
  Q 487.79 633.75 462.17 646.83
  Q 457.26 649.34 452.26 650.75
  Q 447.48 652.10 441.63 652.12
  Q 420.05 652.19 396.40 652.24
  Q 395.82 652.24 395.82 651.67
  Q 395.56 617.01 395.33 581.25
  Q 395.23 566.46 395.69 551.68
  Q 395.70 551.16 396.22 551.11
  L 403.76 550.38
  Z
  M 417.32 571.39
  L 417.96 632.17
  A 1.13 1.13 0.0 0 0 419.10 633.28
  L 437.12 633.10
  A 30.23 29.28 -0.6 0 0 467.04 603.50
  L 467.00 599.02
  A 30.23 29.28 -0.6 0 0 436.46 570.06
  L 418.44 570.25
  A 1.13 1.13 0.0 0 0 417.32 571.39
  Z"
    />
    <path
      :fill="fill ? '#001c32' : 'currentColor'" d="
  M 538.50 550.43
  Q 551.11 551.37 563.74 550.40
  Q 572.45 550.63 581.25 551.01
  Q 583.24 551.10 588.00 552.38
  Q 603.87 556.66 610.53 571.13
  Q 613.23 576.99 613.49 585.45
  Q 613.74 593.37 610.94 599.86
  Q 605.64 612.19 592.97 618.03
  Q 588.52 620.08 582.49 620.78
  Q 575.23 621.62 555.60 622.08
  Q 555.00 622.09 555.00 622.69
  L 555.00 652.49
  Q 555.00 652.98 554.51 653.00
  Q 554.20 653.01 553.96 652.92
  L 533.94 652.66
  A 1.07 1.06 0.6 0 1 532.89 651.60
  Q 532.85 626.63 532.87 597.25
  Q 532.87 583.88 532.31 570.54
  L 532.90 551.44
  Q 532.92 550.90 533.45 550.85
  L 538.50 550.43
  Z
  M 554.95 571.34
  L 555.05 601.68
  A 0.34 0.34 0.0 0 0 555.39 602.02
  L 575.59 601.95
  A 15.48 15.29 -0.2 0 0 591.02 586.61
  L 591.02 586.17
  A 15.48 15.29 -0.2 0 0 575.49 570.93
  L 555.29 571.00
  A 0.34 0.34 0.0 0 0 554.95 571.34
  Z"
    />
    <path
      :fill="fill ? '#001c32' : 'currentColor'" d="
  M 755.49 550.41
  Q 763.83 550.67 772.25 551.05
  Q 774.82 551.16 779.81 552.74
  Q 784.02 554.06 787.15 555.88
  Q 806.80 567.34 804.01 591.57
  C 802.24 606.91 789.17 618.66 774.67 620.65
  Q 760.84 622.55 746.62 621.90
  A 0.59 0.59 0.0 0 0 746.00 622.49
  L 746.00 652.50
  Q 746.00 653.00 745.50 653.00
  L 723.48 653.00
  A 0.46 0.46 0.0 0 1 723.02 652.54
  L 723.02 551.22
  L 723.60 550.96
  Q 724.04 550.76 724.53 550.75
  L 755.49 550.41
  Z
  M 746.03 571.19
  L 745.97 601.77
  A 0.28 0.28 0.0 0 0 746.25 602.05
  L 765.93 602.08
  A 16.02 15.41 0.1 0 0 781.98 586.70
  L 781.98 586.38
  A 16.02 15.41 0.1 0 0 765.99 570.94
  L 746.31 570.91
  A 0.28 0.28 0.0 0 0 746.03 571.19
  Z"
    />
    <path
      :fill="fill ? '#001c32' : 'currentColor'" d="
  M 869.21 550.34
  L 919.60 550.81
  Q 920.23 550.82 920.23 551.44
  L 920.23 569.57
  Q 920.23 570.18 919.61 570.19
  L 885.21 570.66
  L 866.65 570.13
  A 1.36 1.36 0.0 0 0 865.25 571.49
  L 865.31 590.49
  A 1.21 1.20 89.5 0 0 866.52 591.69
  L 913.96 591.47
  L 913.94 610.51
  Q 913.94 611.00 913.45 611.00
  L 867.48 611.01
  Q 866.99 611.01 866.57 611.26
  L 866.08 611.56
  Q 866.52 611.38 865.89 611.35
  A 0.52 0.51 -88.4 0 0 865.36 611.86
  Q 865.30 622.04 865.33 632.02
  Q 865.33 632.25 865.50 632.42
  Q 865.69 632.60 866.00 632.71
  L 920.71 633.01
  A 0.29 0.28 -0.0 0 1 921.00 633.29
  L 921.00 652.50
  Q 921.00 653.00 920.50 653.00
  L 843.69 653.00
  Q 843.06 653.00 843.06 652.37
  L 843.01 550.49
  Q 856.07 551.19 869.21 550.34
  Z"
    />
    <path
      :fill="fill ? '#001c32' : 'currentColor'" d="
  M 988.25 550.40
  Q 1001.94 550.68 1015.72 550.96
  Q 1018.37 551.01 1020.75 551.35
  C 1023.13 551.68 1025.60 551.80 1027.82 552.63
  Q 1040.84 557.44 1047.07 569.13
  C 1050.28 575.17 1050.38 581.72 1049.98 588.33
  Q 1049.51 595.99 1046.06 601.56
  C 1042.00 608.12 1036.27 612.76 1029.42 616.14
  A 0.65 0.65 0.0 0 0 1029.17 617.09
  L 1052.31 651.37
  Q 1053.01 652.40 1051.77 652.49
  Q 1039.85 653.30 1027.79 652.95
  A 1.00 1.00 0.0 0 1 1026.98 652.51
  L 1005.44 620.50
  Q 1005.15 620.07 1004.63 620.07
  L 988.03 620.05
  Q 987.88 619.99 987.71 620.06
  Q 987.29 620.24 987.29 620.70
  L 987.23 651.51
  A 0.99 0.97 -28.2 0 1 987.06 652.06
  L 986.44 652.97
  L 965.31 652.97
  A 0.28 0.28 0.0 0 1 965.03 652.69
  L 965.00 550.36
  Q 976.56 551.37 988.25 550.40
  Z
  M 987.00 571.36
  L 987.00 599.62
  A 0.35 0.35 0.0 0 0 987.35 599.97
  L 1013.45 599.97
  A 14.31 13.50 0.0 0 0 1027.76 586.47
  L 1027.76 584.51
  A 14.31 13.50 -0.0 0 0 1013.45 571.01
  L 987.35 571.01
  A 0.35 0.35 0.0 0 0 987.00 571.36
  Z"
    />
    <path
      :fill="fill ? '#001c32' : 'currentColor'" d="
  M 1183.98 550.40
  Q 1208.37 550.72 1214.62 551.12
  Q 1221.38 551.55 1228.69 556.22
  Q 1241.90 564.66 1239.89 581.20
  Q 1239.15 587.33 1235.64 591.85
  Q 1232.80 595.53 1228.19 598.71
  A 0.65 0.65 0.0 0 0 1228.29 599.84
  C 1236.05 603.40 1242.27 607.75 1244.67 616.25
  C 1246.60 623.10 1246.22 631.24 1242.80 637.67
  C 1239.01 644.79 1229.49 650.44 1221.66 651.22
  C 1219.23 651.45 1217.03 651.96 1214.55 652.01
  Q 1192.69 652.50 1171.14 652.94
  Q 1165.35 653.06 1159.53 652.94
  Q 1159.03 652.93 1159.03 652.43
  L 1159.00 550.38
  Q 1171.36 551.45 1183.98 550.40
  Z
  M 1181.00 570.22
  L 1181.00 591.76
  A 0.26 0.26 0.0 0 0 1181.26 592.02
  L 1202.66 592.02
  A 15.28 9.56 -0.0 0 0 1217.94 582.46
  L 1217.94 579.52
  A 15.28 9.56 0.0 0 0 1202.66 569.96
  L 1181.26 569.96
  A 0.26 0.26 0.0 0 0 1181.00 570.22
  Z
  M 1180.91 610.75
  L 1180.98 632.67
  Q 1180.98 633.00 1181.31 633.00
  Q 1193.75 633.00 1206.26 633.07
  Q 1211.90 633.10 1217.10 631.56
  Q 1220.81 630.46 1222.82 626.25
  Q 1224.59 622.53 1223.78 619.64
  C 1222.53 615.21 1219.92 612.67 1215.67 611.97
  Q 1214.40 611.76 1213.30 611.03
  Q 1197.07 609.54 1180.91 610.75
  Z"
    />
    <path
      :fill="fill ? '#001c32' : 'currentColor'" d="
  M 1402.22 550.39
  Q 1413.79 551.31 1424.93 550.54
  L 1448.77 590.63
  A 0.69 0.69 0.0 0 0 1449.95 590.64
  L 1474.35 550.43
  L 1485.47 550.39
  L 1500.00 550.83
  L 1500.00 551.84
  Q 1498.70 552.77 1498.06 553.75
  Q 1481.93 578.39 1460.73 611.79
  L 1460.37 651.10
  Q 1460.37 651.37 1460.50 651.61
  Q 1460.67 651.89 1460.50 652.27
  A 0.55 0.54 11.3 0 1 1460.02 652.58
  L 1439.30 652.90
  Q 1438.77 653.10 1438.40 652.99
  Q 1438.00 652.88 1438.00 652.47
  L 1438.00 613.48
  A 2.88 2.86 -61.3 0 0 1437.56 611.95
  L 1399.76 552.25
  Q 1399.19 551.35 1400.09 550.77
  Q 1400.81 550.30 1402.22 550.39
  Z"
    />
    <path
      :fill="fill ? '#001c32' : 'currentColor'" d="
  M 348.00 550.46
  L 347.97 652.40
  Q 347.97 653.07 347.30 653.04
  L 329.16 652.17
  Q 328.51 652.14 328.12 651.62
  L 280.46 589.36
  Q 281.13 588.93 279.56 588.53
  Q 279.20 588.44 279.20 588.82
  L 279.36 634.73
  L 279.19 651.70
  Q 279.19 652.15 278.73 652.17
  L 257.72 653.04
  A 0.67 0.67 0.0 0 1 257.03 652.37
  L 257.00 550.43
  Q 259.19 550.91 261.37 550.91
  Q 269.75 550.91 277.89 550.52
  L 324.97 612.28
  A 0.39 0.38 26.3 0 0 325.66 612.05
  L 325.99 550.39
  Q 337.15 551.46 348.00 550.46
  Z"
    />
    <rect :fill="fill ? '#001c32' : 'currentColor'" x="653.00" y="551.01" width="23.00" height="102.00" rx="0.72" />
    <path
      :fill="fill ? '#c8911a' : 'currentColor'" d="
  M 885.21 570.66
  L 866.60 571.05
  Q 865.99 571.06 865.99 571.67
  L 865.99 590.47
  Q 866.00 590.98 866.51 590.99
  L 913.96 591.47
  L 866.52 591.69
  A 1.21 1.20 89.5 0 1 865.31 590.49
  L 865.25 571.49
  A 1.36 1.36 0.0 0 1 866.65 570.13
  L 885.21 570.66
  Z"
    />
    <path
      :fill="fill ? '#c8911a' : 'currentColor'" d="
  M 532.31 570.54
  Q 532.87 583.88 532.87 597.25
  Q 532.85 626.63 532.89 651.60
  A 1.07 1.06 0.6 0 0 533.94 652.66
  L 553.96 652.92
  L 532.62 652.89
  Q 532.12 652.89 532.12 652.38
  L 532.31 570.54
  Z"
    />
    <path
      :fill="fill ? '#c8911a' : 'currentColor'" d="
  M 280.46 589.36
  Q 279.72 606.09 280.01 622.00
  Q 280.08 625.52 279.36 634.73
  L 279.20 588.82
  Q 279.20 588.44 279.56 588.53
  Q 281.13 588.93 280.46 589.36
  Z"
    />
    <path
      :fill="fill ? '#c8911a' : 'currentColor'" d="
  M 1213.30 611.03
  L 1180.91 610.75
  Q 1197.07 609.54 1213.30 611.03
  Z"
    />
    <path
      :fill="fill ? '#c8911a' : 'currentColor'" d="
  M 866.08 611.56
  L 866.00 632.71
  Q 865.69 632.60 865.50 632.42
  Q 865.33 632.25 865.33 632.02
  Q 865.30 622.04 865.36 611.86
  A 0.52 0.51 -88.4 0 1 865.89 611.35
  Q 866.52 611.38 866.08 611.56
  Z"
    />
    <path
      :fill="fill ? '#c8911a' : 'currentColor'" d="
  M 1460.73 611.79
  L 1460.91 652.39
  Q 1460.91 652.91 1460.40 652.91
  L 1439.30 652.90
  L 1460.02 652.58
  A 0.55 0.54 11.3 0 0 1460.50 652.27
  Q 1460.67 651.89 1460.50 651.61
  Q 1460.37 651.37 1460.37 651.10
  L 1460.73 611.79
  Z"
    />
    <path
      :fill="fill ? '#c8911a' : 'currentColor'" d="
  M 988.03 620.05
  L 988.00 652.34
  Q 988.00 652.87 987.48 652.99
  Q 987.05 653.08 986.44 652.97
  L 987.06 652.06
  A 0.99 0.97 -28.2 0 0 987.23 651.51
  L 987.29 620.70
  Q 987.29 620.24 987.71 620.06
  Q 987.88 619.99 988.03 620.05
  Z"
    />
    <path
      :fill="fill ? '#c8911a' : 'currentColor'" d="
  M 1.19 636.28
  Q 1.37 636.53 0.85 637.15
  Q 0.53 637.54 0.62 638.02
  L 0.78 639.00
  Q 0.45 639.34 0.00 638.95
  L 0.00 632.00
  Q 0.45 626.87 0.30 621.73
  Q 0.29 621.57 0.46 621.56
  L 0.47 621.56
  Q 0.64 621.56 0.64 621.73
  Q 0.60 629.01 1.19 636.28
  Z"
    />
    <path
      :fill="fill ? '#c8911a' : 'currentColor'" d="
  M 804.63 802.00
  L 782.26 802.00
  Q 780.29 801.22 778.14 801.40
  Q 778.12 801.40 778.12 801.38
  Q 778.14 801.32 778.20 801.34
  Q 778.91 801.48 781.27 801.08
  Q 782.46 800.88 781.32 800.46
  C 780.07 800.01 778.81 800.00 777.55 799.50
  Q 757.95 791.69 754.11 770.59
  Q 752.78 763.31 754.83 755.25
  C 758.40 741.16 768.75 730.93 783.26 728.02
  C 789.08 726.85 796.51 727.25 802.16 729.31
  C 819.85 735.78 828.88 752.56 825.99 771.05
  Q 824.22 782.36 816.72 789.91
  Q 809.02 797.67 799.65 800.20
  Q 796.86 800.95 799.75 801.00
  Q 826.73 801.42 850.57 801.34
  Q 850.63 801.34 850.63 801.40
  Q 850.62 801.52 850.63 801.42
  Q 827.63 801.74 804.63 802.00
  Z
  M 791.9188 794.3938
  A 30.12 28.86 86.5 0 0 818.8862 762.5681
  A 30.12 28.86 86.5 0 0 788.2412 734.2662
  A 30.12 28.86 86.5 0 0 761.2738 766.0919
  A 30.12 28.86 86.5 0 0 791.9188 794.3938
  Z"
    />
    <path
      :fill="fill ? '#c8911a' : 'currentColor'" d="
  M 704.98 802.00
  L 691.38 802.00
  L 669.51 801.75
  L 669.46 801.26
  L 691.10 801.03
  Q 694.34 801.00 691.17 800.34
  Q 680.67 798.15 671.39 790.45
  A 0.51 0.51 0.0 0 1 671.31 789.74
  L 674.84 785.25
  Q 675.18 784.82 675.60 785.18
  Q 684.69 793.02 696.09 794.61
  Q 702.45 795.50 708.86 792.90
  Q 717.27 789.49 716.60 780.20
  Q 716.47 778.35 715.79 776.65
  C 714.13 772.49 707.27 770.57 703.44 769.39
  Q 694.85 766.75 687.21 764.44
  C 685.27 763.85 683.35 762.76 681.58 761.73
  C 676.68 758.84 673.82 754.02 673.90 748.39
  Q 673.94 746.26 674.00 744.27
  C 674.29 735.91 683.57 729.75 690.51 728.30
  Q 695.24 727.32 702.29 728.00
  Q 711.48 728.89 720.16 734.90
  Q 721.79 736.02 720.66 737.66
  L 718.53 740.74
  Q 717.79 741.80 716.76 741.03
  C 711.92 737.39 706.51 735.07 700.41 734.22
  C 690.67 732.87 679.47 737.08 681.14 748.95
  C 681.97 754.80 691.26 758.61 696.21 759.75
  Q 707.02 762.25 715.02 766.02
  Q 718.06 767.46 720.75 770.78
  Q 722.95 773.50 723.49 777.24
  Q 724.30 782.88 723.00 787.75
  C 721.21 794.39 713.17 799.07 707.01 800.28
  Q 703.70 800.93 707.06 801.17
  Q 707.88 801.23 708.67 801.32
  Q 708.74 801.32 708.75 801.40
  Q 708.75 801.42 708.75 801.44
  Q 708.75 801.47 708.72 801.47
  Q 706.69 801.21 704.98 802.00
  Z"
    />
    <path
      :fill="fill ? '#c8911a' : 'currentColor'" d="
  M 597.21 768.19
  L 597.22 793.28
  A 0.50 0.50 0.0 0 0 597.72 793.78
  L 641.50 793.78
  A 0.50 0.50 0.0 0 1 642.00 794.28
  L 642.01 799.50
  A 0.50 0.50 0.0 0 1 641.51 800.00
  L 590.50 800.00
  A 0.50 0.50 0.0 0 1 590.00 799.50
  L 590.00 728.50
  A 0.50 0.50 0.0 0 1 590.50 728.00
  L 641.50 728.00
  A 0.50 0.50 0.0 0 1 642.00 728.50
  L 642.00 734.45
  A 0.50 0.50 0.0 0 1 641.50 734.95
  L 597.72 735.15
  A 0.50 0.50 0.0 0 0 597.22 735.65
  L 597.22 760.36
  A 0.50 0.50 0.0 0 0 597.72 760.86
  L 636.55 761.06
  A 0.50 0.50 0.0 0 1 637.04 761.63
  L 636.25 766.75
  A 0.50 0.50 0.0 0 1 635.77 767.18
  L 597.70 767.69
  A 0.50 0.50 0.0 0 0 597.21 768.19
  Z"
    />
    <path
      :fill="fill ? '#c8911a' : 'currentColor'" d="
  M 899.47 771.91
  Q 901.58 775.80 904.07 779.18
  Q 911.27 788.97 919.78 799.86
  A 0.04 0.04 0.0 0 1 919.75 799.93
  Q 915.59 800.06 910.84 799.97
  Q 910.61 799.96 910.48 799.78
  L 890.30 772.47
  Q 889.90 771.93 889.23 771.93
  L 869.00 772.14
  Q 868.14 772.15 868.13 773.00
  L 867.94 799.53
  Q 867.94 800.00 867.47 800.00
  L 861.50 800.00
  Q 861.00 800.00 861.00 799.50
  L 861.00 728.71
  Q 861.00 728.12 861.59 728.10
  Q 882.96 727.44 897.75 729.19
  Q 901.90 729.68 906.43 732.03
  C 913.21 735.56 917.47 741.79 917.58 749.62
  Q 917.72 760.62 908.00 766.97
  C 905.51 768.59 902.60 769.33 899.90 770.60
  A 0.95 0.95 0.0 0 0 899.47 771.91
  Z
  M 868.98 736.28
  L 869.02 764.72
  A 0.28 0.28 0.0 0 0 869.31 765.00
  L 894.19 764.95
  A 15.86 14.03 -0.1 0 0 910.02 750.89
  L 910.02 749.95
  A 15.86 14.03 -0.1 0 0 894.13 735.95
  L 869.25 736.00
  A 0.28 0.28 0.0 0 0 868.98 736.28
  Z"
    />
    <path
      :fill="fill ? '#c8911a' : 'currentColor'" d="
  M 968.60 735.00
  L 945.40 735.00
  A 0.40 0.40 0.0 0 1 945.00 734.60
  L 945.02 728.42
  A 0.40 0.40 0.0 0 1 945.42 728.03
  L 1000.86 728.85
  A 0.40 0.40 0.0 0 1 1001.25 729.20
  L 1002.04 734.47
  A 0.40 0.40 0.0 0 1 1001.65 734.93
  L 977.54 735.16
  A 0.40 0.40 0.0 0 0 977.14 735.56
  L 976.95 799.60
  A 0.40 0.40 0.0 0 1 976.55 800.00
  L 969.40 800.00
  A 0.40 0.40 0.0 0 1 969.00 799.60
  L 969.00 735.40
  A 0.40 0.40 0.0 0 0 968.60 735.00
  Z"
    />
    <path
      :fill="fill ? '#c8911a' : 'currentColor'" d="
  M 506.00 772.62
  L 506.00 799.75
  Q 506.00 800.00 505.75 800.00
  L 498.29 800.00
  Q 498.11 800.00 498.11 799.82
  Q 497.57 763.87 498.77 729.44
  A 0.66 0.66 0.0 0 1 499.29 728.82
  Q 501.54 728.32 503.89 728.29
  Q 521.64 728.06 535.64 729.06
  Q 540.46 729.40 546.93 733.81
  C 551.81 737.14 555.22 742.64 555.38 748.67
  Q 555.61 757.39 549.98 763.62
  C 547.11 766.80 542.84 768.45 538.89 770.01
  Q 537.78 770.45 536.93 771.32
  A 0.97 0.96 49.5 0 0 536.84 772.57
  L 556.08 798.76
  Q 557.04 800.08 555.42 800.04
  L 549.58 799.91
  Q 548.29 799.89 547.52 798.85
  L 528.06 772.52
  A 1.27 1.26 71.8 0 0 527.04 772.00
  L 506.63 772.00
  Q 506.00 772.00 506.00 772.62
  Z
  M 505.95 736.28
  L 506.05 764.72
  A 0.27 0.27 0.0 0 0 506.32 764.99
  L 531.76 764.90
  A 16.01 14.30 -0.2 0 0 547.72 750.55
  L 547.72 750.17
  A 16.01 14.30 -0.2 0 0 531.66 735.92
  L 506.22 736.01
  A 0.27 0.27 0.0 0 0 505.95 736.28
  Z"
    />
    <path
      :fill="fill ? '#c8911a' : 'currentColor'" d="
  M 669.46 801.26
  L 669.51 801.75
  Q 657.45 801.81 645.41 801.32
  Q 645.37 801.32 645.37 801.37
  Q 645.37 801.37 645.37 801.38
  Q 645.37 801.39 645.39 801.39
  Q 657.43 801.37 669.46 801.26
  Z"
    />
  </svg>
</template>
